var _session_id = '';
var _user_id = '';
var _sift = window._sift = window._sift || [];
_sift.push(['_setAccount', process.env.SC_SIFT_JS_SNIPPET_KEY]);
_sift.push(['_setUserId', _user_id]);
_sift.push(['_setSessionId', _session_id]);
_sift.push(['_trackPageview']);

(function() {
  function ls() {
    var e = document.createElement('script');
    e.src = 'https://cdn.siftscience.com/s.js';
    document.body.appendChild(e);
  }
  if (window.attachEvent) {
    window.attachEvent('onload', ls);
  } else {
    window.addEventListener('load', ls, false);
  }
})();